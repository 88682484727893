import styled from "styled-components"

export const CardInfomationStyled = styled.div`
  max-width: 608px;
  width: 100%;
  padding: 0px 24px 24px;
  margin: 0 auto;
  position: relative;

  .testimonial-slider {
    position: relative;
    .swiper-container {
      width: 100%;
      box-shadow: 0 2px 70px 0 rgba(4, 2, 33, 0.13);
      border-radius: 12px;
      .swiper-pagination-bullets {
        bottom: 40px;
      }
    }
    .testimonial-action {
      display: none;
      .testimonial-pagination {
        position: absolute;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        .swiper-pagination-bullet {
          width: 20px;
          height: 20px;
          margin: 0 10px;
          outline: 0;
          opacity: 1;
          background-color: rgba(141, 144, 165, 0.5);
          cursor: pointer;
          &.active {
            background-color: #0e1621;
            pointer-events: none;
          }
        }
      }
      .button-next,
      .button-prev {
        position: absolute;
        bottom: -30px;
        width: 17px;
        cursor: pointer;
        z-index: 2;
        outline: 0;
      }
      .button-prev {
        left: 10px;
      }
      .button-next {
        right: 10px;
      }
    }
  }

  .bottom-right-image {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 767px) {
    .bottom-right-image {
      bottom: auto;
      top: 30px;
    }

    .swiper-pagination-bullets {
      display: none;
    }
    .testimonial-slider {
      .testimonial-action {
        display: none;
      }
    }
  }
`

export const CardSlideStyled = styled.div`
  min-height: 441px;
  background-repeat: no-repeat;
  background-size: 37%;
  background-color: #ffffff;
  text-align: center;
  padding: 77px;
  position: relative;

  .slide-content {
    position: relative;
    z-index: 1;
  }

  .image {
    position: relative;
    max-width: 75px;
    margin: 0 auto;
    img {
      border-radius: 50px;
      width: 100%;
      height: 75px;
      border: 1px solid #d8d8d8;
    }
    .circle {
      height: 75px;
      width: 75px;
      border: 2px solid #f3cdb1;
      border-radius: 50%;
      position: absolute;
      top: 1px;
      left: 15%;
    }
  }

  .description {
    color: #0f1722;
    font-family: Calluna-Regular;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 26px 0px;
  }

  .infomation {
    position: absolute;
    bottom: 77px;
    left: 50%;
    transform: translate(-50%, 0);
    .name {
      color: #fb8b42;
      font-size: 15px;
      line-height: 22px;
      font-weight: bold;
    }
    .position {
      color: #676b89;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .top-left-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 37%;
  }

  @media (max-width: 767px) {
    padding: 77px 22px 0px;
    min-height: 395px;
    .infomation {
      position: relative;
      transform: none;
      bottom: 0;
      left: 0;
      .position {
        padding-bottom: 30px;
      }
    }
  }
`
