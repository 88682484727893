import React, { useState, useEffect } from "react"
import Swiper from "react-id-swiper"

import preIcon from "src/componentsV2/images/icons/pre_icon.svg"
import nextIcon from "src/componentsV2/images/icons/next_icon.svg"

import { CardInfomationStyled, CardSlideStyled } from "./style"
import { TestimonialProps } from "./data"
import cardBg from "./images/bg_card.jpg"
import slideBg from "./images/bg_slise.jpg"

interface TestimonialsListProps {
  data: TestimonialProps[]
  className?: string
}

const CardSlide = ({ card }: any) => {
  return (
    <CardSlideStyled
      className={`card-slide ${card.className && card.className}`}
    >
      <div className="slide-content">
        <div className="image">
          <img src={card.image} />
          <div className="circle" />
        </div>
        <div className="description">{card.description}</div>
      </div>
      <div className="infomation">
        <div className="name">{card.name}</div>
        <div className="position">{card.position}</div>
      </div>
      <img className="top-left-image" src={cardBg} />
    </CardSlideStyled>
  )
}

const TestimonialsList = (props: TestimonialsListProps) => {
  const { data } = props
  const [swiper, setSwiper] = useState<any>(null)
  const [activeSlide, setActiveSlide] = useState<number>(0)

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", async () => {
        await setActiveSlide(swiper.realIndex)
      })
    }
  }, [swiper])

  const goNext = (): void => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = (): void => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const goPagination = (idx: number) => {
    if (swiper !== null) {
      swiper.slideToLoop(idx)
    }
  }

  var params = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },

    slidesPerView: 1,
    spaceBetween: 0,
  }

  return (
    <CardInfomationStyled className={props.className && props.className}>
      <img src={slideBg} className="bottom-right-image" />
      <div className="testimonial-slider">
        <Swiper {...params} getSwiper={setSwiper}>
          {data.map((card, index) => (
            <div key={index}>
              <CardSlide card={card} />
            </div>
          ))}
        </Swiper>
        <div className="testimonial-action">
          <div className="testimonial-pagination">
            {data.map((_card, index: number) => (
              <span
                className={`swiper-pagination-bullet ${
                  activeSlide === index ? "active" : ""
                }`}
                key={index}
                onClick={() => goPagination(index)}
              />
            ))}
          </div>
          <button className="button-prev" onClick={goPrev}>
            <img src={preIcon} />
          </button>
          <button className="button-next" onClick={goNext}>
            <img src={nextIcon} />
          </button>
        </div>
      </div>
    </CardInfomationStyled>
  )
}

export default TestimonialsList
