import avatar_michelle from "./images/avatar_michelle_finneran.jpg"
import avatar_jason from "./images/avatar_jason_ackerman.jpg"
import avatar_russ from "./images/avatar_russ_tebaldi.jpg"
import bleach from "./images/bleach.jpg"
import kairos from "./images/kairos.png"
import action_behavior from "./images/action_behavior.jpg"
import remy_cointreau from "./images/remy_cointreau.png"

export interface TestimonialProps {
  image: string
  description: string
  name: string
  position: string
  className?: string
}

export const cardDatas: TestimonialProps[] = [
  {
    image: avatar_michelle,
    description: `AirMason speaks to who we are and what we are about. 
      It is culture-driven and has been really helpful for creating an onboarding process. 
      When we needed help with customizability, 
      we spoke to someone in the chat and they were incredibly helpful and responsive.`,
    name: "Michelle Finneran",
    position: "Founder Shield",
  },
  {
    image: avatar_jason,
    description: `We came to AirMason because our basic PDF handbook was not a reflection of who we are as a company. 
      We want to provide our employees with the best experience possible and AirMason has contributed to making 
      onboarding more captivating and engaging. 
      If an employee needs to look up any policy they know it will be right there!`,
    name: "Jason Ackerman",
    position: "BNA CPA & Advisors",
  },
  {
    image: avatar_russ,
    description: `A lot of handbooks at larger companies tend to get convoluted, but at Top Hat we were
      looking for a product that was engaging and would deliver the right message to our team. We
      needed something people wanted to scroll through and so when we saw AirMason we knew it
      was something we needed.`,
    name: "Russ Tebaldi",
    position: "TopHat",
  },
]

export const brandsData: TestimonialProps[] = [
  {
    image: remy_cointreau,
    description: `The AirMason tool is extremely user-friendly as a user and also as an administrator. 
      It gives an engaging feel for users through its creative features and capabilities. 
      It also provides a seamless experience regarding policy updates, whereas policies and procedures are often revised. 
      My overall experience with AirMason was great. 
      The team provided white glove customer service, super flexible, patient, and extremely responsive 24/7.`,
    name: "Daisha S",
    position: "Associate Manager DE&I",
    className: "rc-item",
  },
  {
    image: action_behavior,
    description: `We LOVE Airmason. It provides unlimited documents and ability to edit content year round within a simple editing tool.
      Sharing with our employees through a custom employee portal that is always accessible.
      Airmason takes the daunting piece of handbooks and turns it into something fun.`,
    name: "Sharon A",
    position: "Chief People Officer",
  },
  {
    image: bleach,
    description: `It's a great tool to enable greater communication across the organisation and the platform really allows you to bring your brand
      to life - it's visually appealing, interactive and a go-to for all people policies, processes and updates like company goals. Considering the time zone (UK <> Canada)
      it has been a breeze - incredibly helpful from start to finish, always to hand for a quick check and fully
      supportive which has made the process easy for a high growth startup like Bleach!`,
    name: "Darren B",
    position: "Head of People & Culture",
  },
  {
    image: kairos,
    description: ` love that this product makes people want to read our handbook.
      Employees are constantly referring back to things they read and asking insightful questions about the policies they encounter.
      My employees are reading and engaging with our handbook and benefits overviews more than at any other company I've worked for.
      It's been terrific getting to create and deploy our handbook.`,
    name: "Ciara O",
    position: "Head of People ",
  },
]
